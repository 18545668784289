<template>
  <div>
    <b-overlay :show="tableLoading" rounded="sm">
      <b-row>
        <b-col cols="12" md="3">
          <span class="heading"> Welcome back, {{ user.first_name }} 👋 </span>
        </b-col>
        <b-col cols="12" md="7" class="pt-0 pb-0">
          <b-row class="mt-n0 ml-0 mr-0 float-right">
            <div class="row">
              <div class="d-flex align-items-center mr-3 mb-1">
                <b-avatar variant="light-primary" rounded>
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>

                <div class="ml-1">
                  <h4 class="mb-0 font-weight-bolder">
                    {{ this.summery.scheduled_shifts }}
                  </h4>
                  <small>Scheduled shifts</small>
                </div>
              </div>
              <div class="d-flex align-items-center mr-3 mb-1">
                <b-avatar variant="light-primary" rounded>
                  <feather-icon icon="UsersIcon" size="18" />
                </b-avatar>

                <div class="ml-1">
                  <h4 class="mb-0 font-weight-bolder">
                    {{ this.summery.staff }}
                  </h4>
                  <small>Total staff</small>
                </div>
              </div>
              <div class="d-flex align-items-center mr-3 mb-1">
                <b-avatar variant="light-primary" rounded>
                  <feather-icon icon="LinkIcon" size="18" />
                </b-avatar>

                <div class="ml-1">
                  <h4 class="mb-0 font-weight-bolder">
                    {{ this.summery.connected_pcns }}
                  </h4>
                  <small>Connected practices</small>
                </div>
              </div>
            </div>
          </b-row>
        </b-col>
        <b-col cols="2" lg="2" md="2">
          <div class="flex flex-wrap float-right mb-2 mb-md-0">
            <b-button
              variant="outline-white"
              class="btn-icon shadow-sm bg-white text-primary mr-1"
              @click="refresh()"
            >
              <feather-icon icon="RefreshCwIcon" size="18" />
            </b-button>
            <b-button
              variant="outline-white"
              class="btn-icon shadow-sm bg-white text-primary mr-1"
              @click="toggleSideBars()"
            >
              <feather-icon icon="SettingsIcon" size="18" />
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- <div class="mt-3">
       <span class="overview font-weight-bolder" style="font-size: 15px;">
        Your weekly overview
       </span>
      </div> -->

      <b-row class="match-height mt-5">
        <b-col v-if="this.shift_applications === 1" lg="4" md="6">
          <div class="d-flex flex-row pt-1">
            <div class="items" style="padding-top: 8px">Shift applications</div>
            <div class="ml-auto">
              <b-dropdown
                dropleft
                text="Drop-Left"
                variant="link"
                class="mr-0"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #b2b2b2"
                    size="18"
                  />
                </template>
                <!--                <b-dropdown-item>-->
                <!--                  <b-button variant="outline-white" class="p-0" @click="">-->
                <!--                    <feather-icon icon="ExternalLinkIcon" class="mr-50"/>-->
                <!--                    <span>View all</span>-->
                <!--                  </b-button>-->
                <!--                </b-dropdown-item>-->
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="updateDashboard(shift_value)"
                  >
                    <feather-icon icon="EyeOffIcon" class="mr-50" />
                    <span>Hide from dashboard</span>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-1">
            <!--            <b-card no-body class=" px-0 mx-0" style="height: 500px;">-->
            <ShiftApplications />
            <!--            </b-card>-->
          </div>
        </b-col>
        <b-col v-if="this.swap_shift === 1" lg="4" md="6">
          <div class="d-flex flex-row pt-1">
            <div class="items" style="padding-top: 8px">Swap shifts</div>
            <div class="ml-auto">
              <b-dropdown
                dropleft
                text="Drop-Left"
                variant="link"
                class="mr-0"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #b2b2b2"
                    size="18"
                  />
                </template>
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="gotoLocumInvoices()"
                  >
                    <feather-icon icon="ExternalLinkIcon" class="mr-50" />
                    <span>View all</span>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="updateDashboard(swap_shift_value)"
                  >
                    <feather-icon icon="EyeOffIcon" class="mr-50" />
                    <span>Hide from dashboard</span>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-1">
            <!--          <b-card no-body style="margin-bottom: 5px; height: 500px;">-->
            <swap-applications />
            <!--          </b-card>-->
          </div>
        </b-col>

        <b-col v-if="this.connection_requests === 1" lg="4" md="6">
          <div class="d-flex flex-row pt-1">
            <div class="items" style="padding-top: 8px">
              Connection requests
            </div>
            <div class="ml-auto pr-0 mr-0">
              <b-dropdown
                dropleft
                text="Drop-Left"
                variant="link"
                class="mr-0"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #b2b2b2"
                    size="18"
                  />
                </template>
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="goToNetwork()"
                  >
                    <feather-icon icon="ExternalLinkIcon" class="mr-50" />
                    <span>View all</span>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="updateDashboard(connection_requests_value)"
                  >
                    <feather-icon icon="EyeOffIcon" class="mr-50" />
                    <span>Hide from dashboard</span>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-1">
            <!--            <b-card no-body class="px-0 mx-0" style="height: 500px;">-->
            <ConnectionRequests />
            <!--            </b-card>-->
          </div>
        </b-col>

        <b-col v-if="this.time_sheets === 1" lg="4" md="6">
          <div class="d-flex flex-row pt-1">
            <div class="items" style="padding-top: 8px">Timesheets</div>
            <div class="ml-auto">
              <b-dropdown
                dropleft
                text="Drop-Left"
                variant="link"
                class="mr-0"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #b2b2b2"
                    size="18"
                  />
                </template>
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="goToTimeSheet()"
                  >
                    <feather-icon icon="ExternalLinkIcon" class="mr-50" />
                    <span>View all</span>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    variant="outline-white"
                    class="p-0"
                    @click="updateDashboard(time_sheet_value)"
                  >
                    <feather-icon icon="EyeOffIcon" class="mr-50" />
                    <span>Hide from dashboard</span>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-1">
            <!--            <b-card no-body style="height: 500px;">-->
            <TimeSheets />
            <!--            </b-card>-->
          </div>
        </b-col>
      </b-row>

      <DashboardSettingsSidebar
        ref="AddLeaveSidebar"
        :is-dashboard-settings-sidebar-active.sync="
          isDashboardSettingsSidebarActive
        "
        :shift-applications="shift_applications"
        :connection-requests="connection_requests"
        :time-sheets="time_sheets"
        @toggleSideBars="toggleSideBars()"
        @updateOfDashboard="showDashboardItems"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BAlert,
  BCardText,
  BLink,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip,
  VBToggle,
  VBModal,
} from "bootstrap-vue";

import DashboardSettingsSidebar from "./sidebars/dashboardSetings";
import profileAPI from "@/apis/modules/profile";
import dashboard from "@/apis/modules/dashboard";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import MomentMixin from "@/mixins/MomentMixin";
import ShiftApplications from "./views/shiftApplications";
import ConnectionRequests from "./views/connectionRequests";
import TimeSheets from "./views/timeSheets";
import SwapApplications from "@/views/dashboard/views/swapApplications";

export default {
  components: {
    SwapApplications,
    BCardText,
    BLink,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
    DashboardSettingsSidebar,
    VuePerfectScrollbar,
    ShiftApplications,
    ConnectionRequests,
    TimeSheets,
  },

  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  mixins: [MomentMixin],

  data() {
    return {
      isDashboardSettingsSidebarActive: false,
      tableLoading: false,
      user: "",
      details: {},
      summery: {},
      shiftApplications: [],
      connectionRequests: [],
      fields: [
        {
          key: "name",
          label: "",
        },

        {
          key: "action",
          label: "",
        },
      ],
      noDataTable: 0,
      currentPage: 1,
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      sortBy: "",
      sortDesc: false,
      shift_applications: "",
      connection_requests: "",
      time_sheets: "",
      swap_shift: "",
      shift_value: 1,
      connection_requests_value: 2,
      time_sheet_value: 3,
      swap_shift_value: 4,
    };
  },

  methods: {
    toggleSideBars() {
      this.isDashboardSettingsSidebarActive =
        !this.isDashboardSettingsSidebarActive;
    },

    refresh() {
      location.reload();
    },

    goToRota() {
      this.$router.push(`/scheduler`);
    },

    showDashboardItems(val) {
      this.shift_applications = val.shift_applications;
      this.connection_requests = val.connection_requests;
      this.time_sheets = val.time_sheets;
      this.swap_shift = val.swap_shift;
    },

    async getAuthUser() {
      try {
        this.tableLoading = true;
        const response = await profileAPI.getCurrentUserDetail();
        this.user = response.data.data;
        this.tableLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async getSummery() {
      try {
        const response = await dashboard.getSummery();
        this.summery = response.data;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async updateDashboard(value) {
      try {
        const item_value = value;
        let payload = {
          shift_applications:
            item_value === 1 ? false : this.shift_applications,
          connection_requests:
            item_value === 2 ? false : this.connection_requests,
          time_sheets: item_value === 3 ? false : this.time_sheets,
          swap_shift_value: item_value === 4 ? false : this.swap_shift,
        };
        const response = await dashboard.updateDashboard(payload);
        await this.getDetailsOfDashboard();
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async getDetailsOfDashboard() {
      try {
        const response = await dashboard.getUpdateDashboardDetails();
        console.log("response", response.data);
        this.shift_applications = response.data[0].shift_applications;
        this.connection_requests = response.data[0].connection_requests;
        this.time_sheets = response.data[0].time_sheets;
        this.swap_shift = response.data[0].swap_shift;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    goToNetwork() {
      this.$router.push(`/networks`);
    },

    goToTimeSheet() {
      this.$router.push(`/approve-timesheet`);
    },
  },

  mounted() {
    this.getAuthUser();
    this.getSummery();
  },
};
</script>

<style>
.heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;

  color: #171822;
}

.box {
  height: 113px;
  top: 168px;
  margin-right: 0px;
  background: #cce6f4;
  border-radius: 4px;
}

.button {
  width: 135px;
  height: 48px;
  top: 218px;
  background: #1b9aaa;
  border-radius: 4px;
}

.overview {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: #171822;
}

.summery {
  font-family: Mulish;
  font-style: normal;
  font-size: 18px;
}

.title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
}

.summery-heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #949494;
}

.items {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #171822;
}
</style>
