<template>
  <div class="">
    <vue-perfect-scrollbar
        class="todo-task-list-wrapper list-group scroll-area min-vh-50"
        style="max-height: 500px"
    >
      <b-card :key="connectionRequest" v-for="connectionRequest in connectionRequests" class="space mr-1">
        <div class="d-flex ">
          <b-avatar size="40" style="background-color: #CCE6F4">
            <img alt="" class="img-fluid" src="@/assets/images/avatars/iconac.png" style="width: 56px; height: 56px;">
          </b-avatar>

          <div class="ml-1" style="padding-top:2px">
            <div style="font-size: 14px; color: #171822; font-family: Mulish">
              <span>{{connectionRequest.name }}</span>
            </div>
            <div style="font-family: Mulish; font-size: 12px; color: #949494;">
            <span class="mr-1">
              {{ connectionRequest.date}} , {{ connectionRequest.time}}
            </span>
            </div>
          </div>


          <div class="float-end" style="display: flex; flex-direction: row;  margin-left: auto;">
            <b-button
                size="23"
                variant="outline-white"
                v-b-tooltip.hover.top="'Accept'"
                class="btn-icon shadow-sm bg-white text-primary p-1"
                @click="approveConnectionRequest(connectionRequest.id)"
            >
              <feather-icon icon="CheckSquareIcon" size="18" />
            </b-button>
<!--            <feather-icon-->
<!--                icon="CheckSquareIcon"-->
<!--                variant="outline-white"-->
<!--                v-b-tooltip.hover.top="'Accept'"-->
<!--                size="18"-->
<!--                class="mt-1 btn-icon bg-white text-primary "-->
<!--                @click="approveConnectionRequest(connectionRequest.id)"-->

<!--            />-->

            <b-button
                size="23"
                variant="outline-white"
                v-b-tooltip.hover.top="'Reject'"
                class="btn-icon ml-1 shadow-sm bg-white text-danger p-1"
                @click="rejectConnectionRequest(connectionRequest.id)"
            >
              <feather-icon icon="XSquareIcon" size="18" />
            </b-button>

<!--            <feather-icon-->
<!--                icon="XSquareIcon"-->
<!--                variant="outline-white"-->
<!--                v-b-tooltip.hover.top="'Reject'"-->
<!--                size="18"-->
<!--                class=" mt-1  ml-3 btn-icon bg-white text-danger"-->
<!--                @click="rejectConnectionRequest(connectionRequest.id)"-->
<!--            />-->
          </div>

        </div>
      </b-card>
    </vue-perfect-scrollbar>

<!--    <b-row v-if="noDataTable !== 0">-->
<!--      <b-col cols="12">-->

<!--        <b-table-->
<!--            :current-page="currentPage"-->
<!--            :per-page="pagination.perPage"-->
<!--            :fields="fields"-->
<!--            :items="connectionRequest"-->
<!--            :sort-by.sync="sortBy"-->
<!--            :sort-desc.sync="sortDesc"-->
<!--            hover-->
<!--            ref="table"-->

<!--            class="mobile_table_css table_shifts"-->

<!--            responsive-->
<!--        >-->
<!--          <template #cell(name)="data">-->
<!--            <div class="d-flex ">-->
<!--              <b-avatar size="40" style="background-color: #CCE6F4">-->
<!--              <img alt="" class="img-fluid" src="@/assets/images/avatars/iconac.png" style="width: 56px; height: 56px;">-->
<!--              </b-avatar>-->
<!--              <div class="ml-1" style="padding-top:8px">-->
<!--                <h5 class=" font-weight-700">-->
<!--                  <span>{{ data.item.name }}</span>-->
<!--                </h5>-->
<!--                <span class="mr-1">-->
<!--                  {{ data.item.date }} , {{ data.item.time }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->

<!--          <template #cell(action)="data">-->
<!--            <div class="float-right" style="display: flex; flex-direction: row;">-->
<!--              <feather-icon-->
<!--                  icon="CheckSquareIcon"-->
<!--                  variant="outline-white"-->
<!--                  v-b-tooltip.hover.top="'Accept'"-->
<!--                  size="18"-->
<!--                  class="mt-1 btn-icon bg-white text-primary "-->
<!--                  @click="approveConnectionRequest(data.item.id)"-->

<!--              />-->

<!--              <feather-icon-->
<!--                  icon="XSquareIcon"-->
<!--                  variant="outline-white"-->
<!--                  v-b-tooltip.hover.top="'Reject'"-->
<!--                  size="18"-->
<!--                  class="mt-1 ml-3 btn-icon bg-white text-danger "-->
<!--                  @click="rejectConnectionRequest(data.item.id)"-->
<!--              />-->
<!--            </div>-->
<!--          </template>-->
<!--        </b-table>-->
<!--      </b-col>-->

<!--      <b-col cols="12" class="position-absolute pagination_style">-->
<!--        <b-row>-->
<!--      <b-col class="d-flex align-items-center pl-1 pb-1 justify-content-center justify-content-sm-start" cols="12" sm="6">-->
<!--        <span class="text-muted pl-1">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>-->
<!--      </b-col>-->

<!--      <b-col class="d-flex align-items-center pr-0 pb-1 justify-content-center justify-content-sm-end" cols="12" sm="6">-->
<!--        <b-pagination-->
<!--            v-model="currentPage"-->
<!--            :per-page="pagination.perPage"-->
<!--            :total-rows="pagination.totalRows"-->
<!--            class="mb-0 mt-1 mt-sm-0 "-->
<!--            first-number-->
<!--            last-number-->
<!--            next-class="next-item"-->
<!--            prev-class="prev-item">-->

<!--          <template #prev-text>-->
<!--            <feather-icon icon="ChevronLeftIcon" size="18"/>-->
<!--          </template>-->

<!--          <template #next-text>-->
<!--            <feather-icon icon="ChevronRightIcon" size="18"/>-->
<!--          </template>-->

<!--        </b-pagination>-->
<!--      </b-col>-->
<!--        </b-row>-->
<!--      </b-col>-->

<!--    </b-row>-->

    <div>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
        <div class="empty-state py-5">
          <img class="img-fluid" src="@/assets/images/pages/Shifts.png" alt="">
          <p class="txt-grey">No Connection Requests</p>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
  BOverlay,
  BTable, VBToggle, VBTooltip
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import dashboard from '@/apis/modules/dashboard'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VBTooltip, VBToggle,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [MomentMixin],
  data(){
    return {
      fields: [
        {
          key: 'name',
          label:'',
        },

        {
          key:'action',
          label:''
        }
      ],
      items: [],
      leaves: [],
      tableLoading: false,
      noDataTable:'',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      connectionRequests:[]
    }
  },
  methods:{
    async connectionRequest() {
      try{
        const Response = await dashboard.getConnectionRequests(this.filterQuery);
        this.connectionRequests = Response.data.data.map((x) => ({
              id:x.id,
             name:x.practice.name,
             date:this.momentFormat(x.created_at,' D MMM'),
             time:`${this.momentFormat(x.created_at,'HH:mm')}`
        }))
        this.noDataTable = Response.data.data.length
        // const paginationResponse = Response.data.meta
        // this.currentPage = paginationResponse.current_page
        // this.pagination.totalRows = paginationResponse.total
        // this.pagination.perPage= paginationResponse.per_page
        // this.pagination.from = paginationResponse.from
        // this.pagination.to = paginationResponse.to
        return this.connectionRequests
      }catch (error){
        this.convertAndNotifyError(error)
      }

    },

    async approveConnectionRequest(id) {
      try{
        const Response = await dashboard.connectionRequestAccept(id)
        if(Response.data) {
          this.showSuccessMessage('Connection Request Approve Successfully')
          await this.connectionRequest()
        }

      }catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async rejectConnectionRequest(id) {
      try{
        const Response = await dashboard.connectionRequestReject(id)
        if(Response.data) {
          this.showSuccessMessage('Connection Request Reject Successfully')
          await this.connectionRequest()
        }
      }catch (error) {
        this.convertAndNotifyError(error)
      }
    }


  },

  mounted () {
    this.connectionRequest()
  }
}
</script>

<style>
.table_shifts table thead{
  display: none !important;
}

.pagination_style{
 margin-top: 450px;
}

.space {
  margin-bottom: 8px;
}

</style>
