<template>
  <div>
    <vue-perfect-scrollbar
        class="todo-task-list-wrapper list-group scroll-area min-vh-50"
        style="max-height: 500px"
    >
      <b-card :key="timeSheet" v-for="timeSheet in timeSheets" class="space mr-1">
        <div class="d-flex ">
          <b-avatar
              size="40"
              :src="getUserImage(timeSheet.id)"
          />

          <div class="ml-1" style="padding-top:2px">
            <div style="font-size: 14px; color: #171822; font-family: Mulish">
              <span>{{timeSheet.user }}</span>
            </div>
            <div style="font-family: Mulish; font-size: 12px; color: #949494;">
            <span class="mr-1">
              {{ timeSheet.count}} timesheets
            </span>
            </div>
          </div>


          <div class="float-end" style="display: flex; flex-direction: row;  margin-left: auto;">
            <feather-icon
                icon="ChevronRightIcon"
                style="color: #B2B2B2;"
                size="18"
                class="mt-1"
                @click ="goToTimeSheet()"
            />
          </div>

        </div>
      </b-card>
    </vue-perfect-scrollbar>
<!--    <b-row v-if="noDataTable !== 0">-->
<!--      <b-col cols="12">-->

<!--        <b-table-->
<!--            :current-page="currentPage"-->
<!--            :per-page="pagination.perPage"-->
<!--            :fields="fields"-->
<!--            :items="getTimeSheets"-->
<!--            :sort-by.sync="sortBy"-->
<!--            :sort-desc.sync="sortDesc"-->
<!--            hover-->
<!--            ref="table"-->

<!--            class="mobile_table_css table_shifts"-->

<!--            responsive-->
<!--        >-->
<!--          <template #cell(name)="data">-->
<!--            <div class="d-flex ">-->
<!--              <b-avatar-->
<!--                  size="40"-->
<!--                  :src="getUserImage(data.item.id)"-->
<!--              />-->


<!--              <div class="ml-1">-->
<!--                <h5 class=" font-weight-700">-->
<!--                  <span>{{ data.item.user }}</span>-->
<!--                </h5>-->
<!--                <span class="mr-1">-->
<!--                   {{data.item.count}} time sheets-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->

<!--          <template #cell(action)="data">-->
<!--            <div class="ml-2">-->
<!--              <feather-icon-->
<!--                  icon="ChevronRightIcon"-->
<!--                  variant="success"-->
<!--                  size="18"-->
<!--                  class="float-right"-->
<!--                  @click ="goToTimeSheet()"-->
<!--              />-->
<!--            </div>-->
<!--          </template>-->
<!--        </b-table>-->
<!--      </b-col>-->

<!--      <b-col cols="12" class="position-absolute pagination_style">-->
<!--        <b-row>-->
<!--      <b-col class="d-flex align-items-center pl-1 pb-1 justify-content-center justify-content-sm-start" cols="12"-->
<!--             sm="6">-->
<!--        <span class="text-muted pl-1">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>-->
<!--      </b-col>-->

<!--      <b-col class="d-flex align-items-center pr-0 pb-1 justify-content-center justify-content-sm-end" cols="12" sm="6">-->
<!--        <b-pagination-->
<!--            v-model="currentPage"-->
<!--            :per-page="pagination.perPage"-->
<!--            :total-rows="pagination.totalRows"-->
<!--            class="mb-0 mt-1 mt-sm-0 "-->
<!--            first-number-->
<!--            last-number-->
<!--            next-class="next-item"-->
<!--            prev-class="prev-item">-->

<!--          <template #prev-text>-->
<!--            <feather-icon icon="ChevronLeftIcon" size="18"/>-->
<!--          </template>-->

<!--          <template #next-text>-->
<!--            <feather-icon icon="ChevronRightIcon" size="18"/>-->
<!--          </template>-->

<!--        </b-pagination>-->
<!--      </b-col>-->
<!--        </b-row>-->
<!--          </b-col>-->

<!--    </b-row>-->
    <div>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
        <div class="empty-state py-5">
          <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z" fill="#F0F8FC"/>
            <path d="M66.1895 87.4937H92.1618M66.1895 100.484H83.5058" stroke="#1B9AAA" stroke-width="3" stroke-linecap="round"/>
            <path d="M81.3234 40.7456H54.3247C50.8315 40.7456 48 43.5763 48 47.0666V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0303" stroke="#171822" stroke-width="3" stroke-linecap="round"/>
            <path d="M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.471 62.0764 99.5463 62.0764C92.622 62.0764 87.0081 56.4629 87.0081 49.5382C87.0081 42.6135 92.622 37 99.5463 37C101.268 37 102.91 37.3472 104.403 37.9755" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
            <path d="M109.825 42.3568C109.157 41.4007 108.357 40.5423 107.453 39.8071" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
            <path d="M98.7554 43.0996V50.1572C98.7554 50.2105 98.7983 50.2536 98.8525 50.2536H104.855" stroke="#1B9AAA" stroke-width="2.5" stroke-linecap="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.872 55.3193C140.222 55.3193 142.127 57.2249 142.127 59.5747C142.127 61.9244 140.222 63.83 137.872 63.83C135.522 63.83 133.616 61.9244 133.616 59.5747C133.616 57.2249 135.522 55.3193 137.872 55.3193Z" fill="#DCEEF8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M149.788 41.7021C153.078 41.7021 155.745 44.3694 155.745 47.6596C155.745 50.9498 153.078 53.617 149.788 53.617C146.498 53.617 143.831 50.9498 143.831 47.6596C143.831 44.3694 146.498 41.7021 149.788 41.7021Z" fill="#DCEEF8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z" fill="#DCEEF8"/>
          </svg>

          <p class="txt-grey">No Time Sheets</p>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
  BOverlay,
  BTable, VBToggle, VBTooltip
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import dashboard from '@/apis/modules/dashboard'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VBTooltip,
    VBToggle,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  mixins: [MomentMixin],
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: ''
        },

        {
          key: 'action',
          label: ''
        }
      ],
      items: [],
      leaves: [],
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      timeSheets:[]
    }
  },
  methods: {
    async getTimeSheets() {
      try {
        const response = await dashboard.getTimeSheets(this.currentPage, 5)
       this.timeSheets = response.data.data.map((x) => ({
          id: x.id,
          user: x.first_name + ' ' + x.last_name,
          avatar: '',
          count: x.shift_time_sheet_count
        }))
        this.noDataTable = response.data.data.length
        // const paginationResponse = response.data.meta
        // this.currentPage = paginationResponse.current_page
        // this.pagination.totalRows = paginationResponse.total
        // this.pagination.perPage = paginationResponse.per_page
        // this.pagination.from = paginationResponse.from
        // this.pagination.to = paginationResponse.to
        return  this.timeSheets
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },

    goToTimeSheet() {
      this.$router.push(`/approve-timesheet`)
    }

  },

  mounted () {
    this.getTimeSheets()
  }

}
</script>

<style>
.table_shifts table thead{
  display: none !important;
}

.pagination_style{
  margin-top: 450px;
}

.space {
  margin-bottom: 8px;
}
</style>
